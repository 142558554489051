import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Barbwire Barbecue",
  pageDescription:
    "Find out how a barbecue food truck launched a brick-and-mortar restaurant with SpotOn to streamline ordering and dramatically increase sales.",
  title: "Opening a barbecue joint with smart tech",
  subTitle: `How Barbwire Barbecue launched their restaurant with SpotOn.`,
  businessName: "Barbwire Barbecue",
  industry: "Restaurants & hospitality, quick-service",
  location: "Eudora, Kansas",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.ORDER,
    spotonProducts.LOYALTY,
  ],
  description:
    "In late 2020, twin brothers Jay and Jason Musick turned their popular barbecue food truck—Barbwire Barbecue—into a brick-and-mortar restaurant. And by launching their new counter-service establishment with SpotOn, they've been able to give their customers online ordering options and dramatically increase sales.",
  quoteData: {
    imgName: "barbwire.png",
    quote: `“If someone is starting a restaurant and considering SpotOn, I’d say absolutely. If I have an issue, they address it, and it gets fixed. That, to me, is what it’s all about. If somebody is looking for a POS system that's user friendly, easy to learn, and doesn’t require a lot of time—go for it.”`,
    personName: "Jason Musick",
    personTitle: "Owner, Barbwire Barbecue",
  },
  goal: `The Musick brothers needed technology that would help transform their popular barbecue food truck into a full-fledged restaurant. They were looking for a POS system with online ordering, a loyalty rewards program, and responsive customer support.`,
  solution: `SpotOn Restaurant with SpotOn Order helps Barbwire bring in 65% of revenue through online ordering. And while SpotOn Loyalty keeps customers returning, the full-time support enables the Musicks to spend more time on their business and less on tech.`,
  solutionReadMore: {
    text: `Read their story.`,
    url: `https://www.spoton.com/blog/small-town-barbecue-big-time-following/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `40%`, description: `increase in revenue` },
      {
        title: `$10,000`,
        description: `in loyalty spending every month`,
      },
      { title: `5 – 10`, description: `hours saved on admin every week` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: '"Slow" food meets fast tech',
    imageName: "blue-barn.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-06-24",
    href: "/case-studies/blue-barn",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
];
